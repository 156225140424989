export const getRedirectLanguage = () => {
    if (typeof navigator === `undefined`) {
        return "en";
    }

    const lang = navigator && navigator.language && navigator.language.split("-")[0];
    if (!lang) return "en";

    switch (lang) {
        // add missing language here as switch, case 'zh': return 'zh'; break;
        case "kh":
            return "kh";
        default:
            return "en";
    }
};

export default getRedirectLanguage;
